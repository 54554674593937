import * as React from "react"
import Box      from '@mui/material/Box'
import Slider   from '@mui/material/Slider'
import Paper    from '@mui/material/Paper'

import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Chip from '@mui/material/Chip';

import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

//icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';


function createData(name, score) {
  return { name, score };
}

const rows = [
  createData('UI/UX/Design', 100),
  createData('Photoshop, Illustrator and other design tools', 90),
  createData('REST / Web Services, Websocket', 80),
  createData('Oauth2, OpenId, JWT, Integrations', 80),
  createData('AWS Web Services, Heroku', 50),
  createData('Cloud Services in GCP, Azure', 25),
  createData('Node / Express / Mongodb', 70),
  createData('Javascript, Jquery, HTML, CSS', 70),
  createData('Java, JSP, Spring Boot, DropWizard, Maven', 70),
  createData('Android', 50),
  createData('Python / Flask', 50),
  createData('Nginx, Apache Httpd', 50),
  createData('Docker', 40),
  createData('C, C++', 25),
  createData('SQL, SQLite, PostgresQL', 20),
  createData('Machine Learning / AI', 20),
];



export default function Index() {
    return <>
        <div class="container">
        <hr />
        <a href="/">Back To Homepage</a>
        <hr />
        <h3>About Me</h3>
        <p><LinkedInIcon /><a href="https://www.linkedin.com/in/jonathan-paek-a3041226/">LinkedIn Profile</a></p>
        <p>Hello! I'm Jonathan Paek, thank you for visiting my site.</p>
        <h3>Education</h3>
        <p>
        <ul>
        <li>M.Sc., Computer Science | Georgia Tech (December 2022)</li>
        <li>B.Sc., Computer Science | UC Davis (June 2009)</li>
        </ul>
        </p>

        <h3>Early History, Memory Lane</h3>
        <p>Just a list of things from childhood:</p> 
        <p>
        <ul>
        <li>First console: Nintendo NES - Favorite: Zelda</li>
        <li>Childhood favorite books: Willa Wonka and the Glass Elevator, Tom Sawyer</li>
        <li>First time I saw code: BASIC, Apple Plus, 5.25 floppy</li>
        <li>First homepage built in '95: HTML, BBEdit, Dreamweaver user '98</li>
          <li>Cross-browser compatibility was no fun: &lt;--[if IE] ... \MSIE</li>
          <li>First Browsers: Mosaic, Netscape, Search: Hotbot, Lycos, Webcrawler</li>
          <li>First Apple Use: Apple II, 5.25 floppy in grade-school</li>
          <li>First Machintosh Use: Apple SE</li>
          <li>First chat with A.I.: ELIZA</li>
          <li>First chat over internet: via telnet and later ICQ, AIM, MSN Messenger</li>
          <li>First Home Internet: 32k/56k modem and after DSL</li>
          <li>First PC: 25mhz (33mhz over-clocked)</li>
          <li>First graphics card: Diamond Multimedia 4MB</li>
          <li>First favorite softwares: Oregon Trail, QuarkXPress, Screensavers, Planet Simulator</li>
          <li>First blog posts: Geocities, Xanga</li>
          <li>Learned ASP/VBScript, e-commerce</li>
          <li>Learned Windows Adminstration, IIS, SQL Server </li>
          <li>Learned Network configuration, TCP/IP, Subnet, Routers, NAT, File Server</li> 
          <li>First Made Backup & Recovery: With Norton Ghost</li> 
          <li>First network cabling: CAT-5 twisted pair wiring and crimping</li>
          <li>First solder: 8MB SIMM</li>
          <li>Ran a small FTP server and domain hosting (mostly for friends/family), used Plesk</li>
          <li>First server code - CGI-bin, hello world </li>
          <li>Perl, Assembly, C (Code Warrior)</li>
          <li>First programming classes in order: C, Assembly, Java, C++</li>
          <li>Winamp, Lime, Napster, good times</li>
          <li>MIDI and .txt collector</li>
          <li>Childhood PC games: Oregon Trail, Marathon, Starcraft, Counterstrike</li>
          <li>First distributed contribution: @SETI, are you out there?</li>
          <li>Favorite High School subject: AP Physics</li>
          <li>First installed linux distribution: Mandrake with many floppy disks</li>
        </ul>

        </p>
        <p>That's all I can think of in a single sitting.  I hope you enjoyed, learned something, even better if you sparked an idea for something.</p>

        <hr />

        <h3>2009-2022</h3>
        <p>A short timeline since graduation</p>
        <Timeline position="alternate">
        <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="filled" />
        <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent><CheckCircleIcon color="success"/> 
            (🎓 12/2022) Degree Awarded M.S. Computer Science
            <p>Georgia Institute of Technology, Atlanta, GA</p> 
        </TimelineContent>
        </TimelineItem>
        <TimelineItem>
                <TimelineSeparator>
                <TimelineDot variant="outlined" />
                <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                    <CheckCircleIcon color="success"/> (2019) LinkedIn Corporation 
                    <p>Candidate Onboarding, APIs, Integrations</p> 
                </TimelineContent>
        {/**************************************
          ******** Sample using CheckCirleIcon
          ***************************************

                <TimelineContent>
                <CheckCircleIcon color="success"/> (2021) Gitflow and Actions. 
                <hr />
                <WatchLaterIcon />
                <CheckCircleIcon color="info" /> Done -  Within 1 year expand skill by creating and utilizing a self-hosted runner for actions.</TimelineContent>
         *****************************************/}

        </TimelineItem>


        <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" />
        <TimelineConnector />
        </TimelineSeparator>
                <TimelineContent>
                    <CheckCircleIcon color="success"/> (2016-2019) Samsung
                    <p>Internet of Things, Smart Home</p> 
                </TimelineContent>
        </TimelineItem>
        <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" />
        <TimelineConnector />
        </TimelineSeparator>
                <TimelineContent>
                    <CheckCircleIcon color="success"/> (2015) Samsung
                    <p>Internet of Things, Smart Home</p> 
                </TimelineContent>
        </TimelineItem>

        <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" />
        <TimelineConnector />
        </TimelineSeparator>
                <TimelineContent>
                    <CheckCircleIcon color="success"/> (2013-2015) Google with Vaco Technologies
                    <p>Payments, API, Partner Onboarding, Support</p> 
                </TimelineContent>
        </TimelineItem>
        <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" />
        <TimelineConnector />
        </TimelineSeparator>
                <TimelineContent>
                    <CheckCircleIcon color="success"/> (2010-2013) SpecialDeals / Payever
                    <p>Startup, AWS, Infra, Java, PHP, MySQL, Web Services, Android</p> 
                </TimelineContent>
        </TimelineItem>
        <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" />
        <TimelineConnector />
        </TimelineSeparator>
                <TimelineContent>
                    <CheckCircleIcon color="success"/> (2009-2010) Integrated Building Solutions
                    <p>Building Intelligence, Data Center, Monitoring & Control</p> 
                </TimelineContent>
        </TimelineItem>
        <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" />
        <TimelineConnector />
        </TimelineSeparator>
                <TimelineContent>
                    <CheckCircleIcon color="success"/> (🎓 2009) Degree Awarded B.S. Computer Science 
                    <p>University of California, Davis, CA</p> 
                </TimelineContent>
        </TimelineItem>




        </Timeline>

        <hr />
        <a href="/">Back To Homepage</a>
        <hr />
        </div>         
        </>
}


